import { Form, Input, Switch } from 'antd';

import { ICommissionsListItem } from '../../../features/CommissionsTable/model/types';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: keyof ICommissionsListItem;
  title: string;
  inputType: 'checkbox' | 'text';
  record: ICommissionsListItem;
  index: number;
  children: React.ReactNode;
}

export function EditableCell({ editing, dataIndex, inputType, record, children, ...restProps }: EditableCellProps) {
  const inputNode = inputType === 'checkbox' ? <Switch defaultChecked={Boolean(record?.[dataIndex])} /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          valuePropName={inputType === 'checkbox' ? 'checked' : undefined} // лечим ошибку: [antd: Switch] `value` is not a valid prop, do you mean `checked`
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}
