import { useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';
import { useBlockUserMutation, useResetUser2FAMutation } from 'widgets/User';

export function useUserActionsLogic() {
  const params = useParams();
  const { showError } = useAppMessage();

  const [blockUser] = useBlockUserMutation();
  const [resetUser2FA] = useResetUser2FAMutation();

  function handleBlockUser() {
    if (params.id === undefined) return showError({ error: 'User id is undefined' } as IResponseError);

    blockUser({ userId: params.id });
  }

  function handleResetUser2FA() {
    if (params.id === undefined) return showError({ error: 'User id is undefined' } as IResponseError);

    resetUser2FA({ userId: params.id });
  }

  return {
    handleBlockUser,
    handleResetUser2FA,
  };
}
