import { TPanelType } from 'shared/types/Types/TPanelType';
import { IUsersRequest } from 'widgets/Users';

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { IChangeUserRequest, IUserRequest, IUserResponse, IWalletsResponse } from '../model/types';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery,
  endpoints: (builder) => ({
    getUser: builder.query<IUserResponse, IUserRequest>({
      query: (params) => {
        return {
          url: `/${params.panelType}/users/${params.userId}`,
        };
      },
    }),
    changeUser: builder.mutation<IUserResponse, IChangeUserRequest>({
      query: ({ panelType, userId, body }) => {
        return {
          url: `/${panelType}/users/${userId}`,
          method: 'PUT',
          body,
        };
      },
    }),
    getUserBalance: builder.query<{ data: { balance: string } }, { panelType: TPanelType; userId: string }>({
      query: (params) => {
        return {
          url: `/${params.panelType}/users/${params.userId}/balance`,
        };
      },
    }),
    getUserWallets: builder.query<IWalletsResponse, IUsersRequest>({
      query: (params) => {
        const updatedParams = {
          // 'sorting[createdAt]': params.createdAt,
          // 'paging[page]': params.page,
          // 'paging[perPage]': params.perPage,
          // 'filter[email]': params.email,
          'filter[userId]': params.userId,
          // 'filter[createdFrom]': params.createdFrom,
          // 'filter[createdTo]': params.createdTo,
          // 'filter[hasBalance]': params.hasBalance,
          // 'filter[profileId]': params.profileId,
        };

        return {
          url: `/${params.panelType}/wallets/`,
          params: updatedParams,
        };
      },
    }),
    blockUser: builder.mutation<unknown, { userId: string }>({
      query: (params) => ({
        url: `/wallet/users/${params.userId}`,
        method: 'DELETE',
      }),
    }),
    resetUser2FA: builder.mutation<unknown, { userId: string }>({
      query: (params) => ({
        url: `/wallet/users/${params.userId}/otp`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUserBalanceQuery,
  useChangeUserMutation,
  useGetUserWalletsQuery,
  useBlockUserMutation,
  useResetUser2FAMutation,
} = userApi;
