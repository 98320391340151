import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { cn } from 'utils/classnames';

import arrowDown from '../../../assets/icons/arrow-down.svg';
import Error from '../Error/Error';

import styles from './customSelect.module.scss';

interface ICustomSelectProps extends SelectProps {
  error: string | false | undefined;
}

function CustomSelect({ className, error, value, ...props }: ICustomSelectProps) {
  return (
    <>
      <Select
        status={error ? 'error' : ''}
        bordered={false}
        className={cn(styles.select, className)}
        value={value || null}
        suffixIcon={<img src={arrowDown} alt='arrow' />}
        popupClassName={styles.dropdown}
        virtual={false}
        {...props}
      />
      {error && <Error error={error} />}
    </>
  );
}

export default CustomSelect;
