import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { encodeToBase64 } from 'utils/encodeToBase64';
import { useAppMessage } from 'utils/messageContext';
import { IUserResponse, useChangeUserMutation, useGetUserBalanceQuery, useGetUserQuery } from 'widgets/User';

function useUserPersonalDataLogic() {
  const location = useLocation();
  const params = useParams();
  const [userData, setUserData] = useState<IUserResponse['data'] & { password?: string }>();
  const { showError, showSuccess } = useAppMessage();

  const { data, isLoading, isError, error } = useGetUserQuery({
    panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });
  const {
    data: balanceData,
    isLoading: isBalanceLoading,
    isError: isBalanceError,
    error: balanceError,
  } = useGetUserBalanceQuery({
    panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });

  const [putNewUserData] = useChangeUserMutation();

  function changeUserData(e: React.ChangeEvent<HTMLInputElement>) {
    setUserData((prev) => {
      if (prev === undefined) return;

      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  async function putUserData() {
    try {
      const transformedUserData = JSON.parse(JSON.stringify(userData));

      if (transformedUserData?.password) transformedUserData.password = encodeToBase64(transformedUserData.password);

      const response = await putNewUserData({
        body: transformedUserData,
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        userId: params.id ?? 'frontend error',
      }).unwrap();

      if (response.error) return showError(response.error.data.errors[0] as IResponseError);

      showSuccess('Данные успешно обновлены');
    } catch (error) {
      showError(error as IResponseError);
    }
  }

  useEffect(() => {
    if (data) setUserData(data.data);
  }, [data]);

  useEffect(() => {
    if (isError || isBalanceError) {
      showError((error ?? isBalanceError) as IResponseError);
    }
  }, [isError, error, isBalanceError, balanceError]);

  return {
    isLoading,
    data,
    userData,
    isBalanceLoading,
    changeUserData,
    balanceData,
    putUserData,
  };
}

export default useUserPersonalDataLogic;
