import { Input, Space, Table, Tag } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import Highlighter from 'react-highlight-words';
import { NavLink } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';
import { transformDataForTableFilter } from 'utils/transformDataForTableFilter';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import { SearchOutlined } from '@ant-design/icons';

import { IRate } from '../../model/types';
import { DataIndex, useRatesTableLogic } from '../../model/useRatesTableLogic';

import styles from './ratesTable.module.scss';

export function RatesTable() {
  const { searchInput, handleSearch, handleReset, filteredData, searchedColumn, searchText, isLoading } = useRatesTableLogic();

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<IRate> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size='small'>
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size='small'>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      (record[dataIndex] as string)
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<IRate> = [
    {
      title: 'ID',
      dataIndex: 'rateId',
      key: 'rateId',
    },
    {
      title: 'Currency from',
      dataIndex: 'currencyCodeFrom',
      key: 'currencyCodeFrom',
      filters: transformDataForTableFilter<IRate>([], 'currencyCodeFrom'),
    },
    {
      title: 'Currency to',
      dataIndex: 'currencyCodeTo',
      key: 'currencyCodeTo',
      filters: transformDataForTableFilter<IRate>([], 'currencyCodeTo'),
    },
    {
      title: 'Rate auto',
      dataIndex: 'rateAuto',
      key: 'rateAuto',
    },
    {
      title: 'Rate manual',
      dataIndex: 'rateManual',
      key: 'rateManual',
    },
    {
      title: 'Manual',
      dataIndex: 'isManual',
      key: 'isManual',
      render: (isManual) =>
        isManual ? (
          <Tag color={'green'} key={'plus'}>
            +
          </Tag>
        ) : (
          <Tag color={'volcano'} key={'plus'}>
            -
          </Tag>
        ),
    },
    {
      title: 'Enabled',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      render: (isEnabled) =>
        isEnabled ? (
          <Tag color={'green'} key={'plus'}>
            +
          </Tag>
        ) : (
          <Tag color={'volcano'} key={'plus'}>
            -
          </Tag>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Button>
          <NavLink to={`${window.location.pathname}/${record.rateId}?rateFrom=${record.currencyCodeFrom}&rateTo=${record.currencyCodeTo}`}>
            OPEN
          </NavLink>
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table rowKey={(row) => row.rateId} columns={columns} dataSource={filteredData} loading={isLoading} />
    </>
  );
}
