import { authApi, authSlice } from 'entities/Auth';
import { transactionsApi } from 'entities/Transactions';
import { commissionsApi } from 'features/CommissionsTable';
import { markupTableApi } from 'features/MarkupTable';
import { ratesApi } from 'features/RatesTable';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { userApi } from 'widgets/User';
import { usersApi } from 'widgets/Users';

import { combineReducers, configureStore } from '@reduxjs/toolkit';

const combinedReducers = combineReducers({
  auth: authSlice,
  [authApi.reducerPath]: authApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [ratesApi.reducerPath]: ratesApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [markupTableApi.reducerPath]: markupTableApi.reducer,
  [commissionsApi.reducerPath]: commissionsApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
});

export const store = configureStore({
  reducer: (state, action) => combinedReducers(state, action),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      usersApi.middleware,
      authApi.middleware,
      userApi.middleware,
      ratesApi.middleware,
      commissionsApi.middleware,
      transactionsApi.middleware,
      markupTableApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof combinedReducers>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
