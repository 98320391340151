import { Content } from 'antd/es/layout/layout';
import { NavLink } from 'react-router-dom';

import styles from './home.module.scss';

export function HomePage() {
  return (
    <Content className={styles.home}>
      <h2>Welcome to DeltaWallet admin panel</h2>
      <h3>
        Please select a king of administration <NavLink to='/wallet/users'>wallet</NavLink> or <NavLink to='#'>merchant</NavLink>{' '}
      </h3>
    </Content>
  );
}
