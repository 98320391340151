import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';
import { useGetUserWalletsQuery } from 'widgets/User';

export function useUserAccountsLogic() {
  const location = useLocation();
  const params = useParams();
  const [isBalanceModalOpen, setIsBalanceModalOpen] = useState(false);
  const { showError } = useAppMessage();

  const { data, isLoading, isError, error } = useGetUserWalletsQuery({
    panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });

  useEffect(() => {
    if (isError) showError(error as IResponseError);
  }, [isError, error, showError]);

  return { setIsBalanceModalOpen, isLoading, data, isBalanceModalOpen };
}
