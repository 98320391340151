import { Skeleton, Table } from 'antd';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import Input from 'shared/ui/Input/Input';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';

import fingerInCircle from '../../../../assets/icons/fingerInCircle.svg';
import { useUserAccountsLogic } from '../../model/useUserAccountsLogic';

import styles from './userAccounts.module.scss';

export function UserAccounts() {
  const { setIsBalanceModalOpen, isLoading, data, isBalanceModalOpen } = useUserAccountsLogic();

  const columns = [
    {
      title: 'Account number',
      dataIndex: 'walletNumber',
      key: 'walletNumber',
    },
    {
      title: 'Amount',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: () => (
        <div className={styles.control_btns}>
          <Button className={styles.btn} onClick={() => setIsBalanceModalOpen(true)}>
            +
          </Button>
          <Button className={styles.btn} onClick={() => setIsBalanceModalOpen(true)}>
            -
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <Table
          rowKey={(row) => row.walletNumber}
          className={styles.table}
          pagination={false}
          columns={columns}
          dataSource={data?.list.map((wallet) => ({
            walletNumber: wallet.walletNumber,
            balance: wallet.balance,
          }))}
          loading={false}
        />
      )}

      <CustomModal width={560} className={styles.modal} isOpen={isBalanceModalOpen} onClose={() => setIsBalanceModalOpen(false)} centered>
        <ModalTitle>
          <img src={fingerInCircle} alt='finger in circle' />
          <span>Change balance</span>
        </ModalTitle>
        <br />
        <form onSubmit={() => null}>
          <Input />
          <br />
          <br />
          <Button className={styles.confirm_btn}>Confirm</Button>
        </form>
      </CustomModal>
    </div>
  );
}
