import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { IRatesResponse, IRatesRequest } from '../model/types';

export const ratesApi = createApi({
  reducerPath: 'ratesApi',
  baseQuery,
  endpoints: (builder) => ({
    getRates: builder.query<IRatesResponse, IRatesRequest>({
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[email]': params.email,
          'filter[userId]': params.userId,
          'filter[createdFrom]': params.createdFrom,
          'filter[createdTo]': params.createdTo,
          'filter[hasBalance]': params.hasBalance,
          'filter[profileId]': params.profileId,
        };

        return {
          url: `/${params.panelType}/rates/`,
          params: updatedParams,
        };
      },
    }),
  }),
});

export const { useGetRatesQuery } = ratesApi;
