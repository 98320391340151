import { ICommissionsListItem } from './types';
import { Form, InputRef } from 'antd';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { useAppDispatch } from 'app/GlobalRedux/store';
import { Key, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

import { commissionsApi, useGetCommissionsQuery, usePutCommissionMutation } from '../api/commissionsApi';

export type DataIndex = keyof ICommissionsListItem;

export function useCommissionsTableLogic() {
  const location = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const [form] = Form.useForm();
  const searchInput = useRef<InputRef>(null);
  const queryParams = new URLSearchParams(location.search);
  const { showError } = useAppMessage();

  const rateFrom = queryParams.get('rateFrom') ?? undefined;
  const rateTo = queryParams.get('rateTo') ?? undefined;

  const { data, isLoading, isError, error, isFetching, refetch } = useGetCommissionsQuery({
    panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    page: 1,
    perPage: 50,
    rateFrom: rateFrom,
    rateTo: rateTo,
  });

  const [commission, commissionResponse] = usePutCommissionMutation();

  const isEditing = (record: ICommissionsListItem) => record.commissionId === editingKey;

  const edit = (record: ICommissionsListItem) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.commissionId);
  };

  const cancel = () => setEditingKey(null);

  const save = async () => {
    try {
      const row: ICommissionsListItem = await form.validateFields();
      setEditingKey(null);

      commission({
        body: {
          commissionValue: row.commissionValue,
          commissionPercent: row.commissionPercent,
          minValue: row.minValue,
          maxValue: row.maxValue,
          isPercent: row.isPercent,
          isEnabled: row.isEnabled,
        },
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        commissionId: params.id ?? 'frontend error',
      });

      refetch();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const handelCommissionFilter = (value: boolean | Key, record: ICommissionsListItem): boolean => {
    dispatch(
      commissionsApi.endpoints.getCommissions.initiate({
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        page: 1,
        perPage: 50,
        operation: value as string,
      })
    );
    return true;
  };

  useEffect(() => {
    dispatch(
      commissionsApi.endpoints.getCommissions.initiate({
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        page: 1,
        perPage: 50,
        rateFrom: searchedColumn === 'rateFrom' ? searchText : undefined,
        rateTo: searchedColumn === 'rateTo' ? searchText : undefined,
      })
    );
  }, [searchedColumn, searchText]);

  useEffect(() => {
    if (isError || commissionResponse.isError) showError((error ?? commissionResponse.error) as IResponseError);
  }, [isError, error, commissionResponse.isError, commissionResponse.error]);

  return {
    searchInput,
    handleSearch,
    handleReset,
    searchedColumn,
    searchText,
    isEditing,
    edit,
    save,
    cancel,
    data,
    form,
    isLoading,
    isFetching,
    handelCommissionFilter,
  };
}
