import { Input, Space, Table } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import Highlighter from 'react-highlight-words';
import { NavLink } from 'react-router-dom';
import { Button } from 'shared/ui/Button/Button';
import Checkbox from 'shared/ui/Checkbox/Checkbox';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import { SearchOutlined } from '@ant-design/icons';

import { IUser } from '../../model/types';
import { DataIndex, useUsersLogic } from '../../model/useUsersLogic';

import styles from './users.module.scss';

export function Users() {
  const { searchInput, handleSearch, handleReset, showUsersWithBalance, filteredData, searchedColumn, searchText, isLoading } =
    useUsersLogic();

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<IUser> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size='small'>
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size='small'>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      (record[dataIndex] as string)
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<IUser> = [
    {
      title: 'ID',
      dataIndex: 'userId',
      key: 'userId',
      ...getColumnSearchProps('userId'),
    },
    {
      title: 'Creation date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt).getDate() - new Date(b.createdAt).getDate(),
      sortDirections: ['descend', 'ascend'],
      render: (date) => transformDateToUserFriendly(date),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Balance',
      dataIndex: 'amountUSD',
      key: 'amountUSD',
      render: (balance) => `${balance} USD`,
    },
    {
      title: 'Active accounts',
      dataIndex: 'wallets',
      key: 'wallets',
      width: '20%',
      render: (wallets: IUser['wallets']) => `${wallets.length ?? '0'}`,
    },
    {
      title: 'Action',
      dataIndex: 'btn',
      key: 'btn',
      render: (_, record) => (
        <Button>
          <NavLink to={`${window.location.pathname}/${record.userId}`}>OPEN</NavLink>
        </Button>
      ),
    },
  ];

  return (
    <>
      <Checkbox onChange={showUsersWithBalance} className={styles.checkbox}>
        Отображать профили с балансом
      </Checkbox>
      <Table rowKey={(row) => row.userId} columns={columns} dataSource={filteredData} loading={isLoading} />
    </>
  );
}
