import { IMarkupListItem } from './types';
import { Form, InputRef } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

import { useGetMarkupsQuery, usePutMarkupMutation } from '../api/markupTableApi';

export type DataIndex = keyof IMarkupListItem;
export interface CustomColumnType<T> extends ColumnType<T> {
  editable?: boolean;
}

export function useMarkupTableLogic() {
  const location = useLocation();
  const params = useParams();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [editingKey, setEditingKey] = useState<number | null>(null);
  const [form] = Form.useForm();
  const { showError } = useAppMessage();

  const { data, isLoading, isError, error, refetch, isFetching } = useGetMarkupsQuery({
    panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    page: 1,
    perPage: 50,
    markupId: Number(params?.id ?? 1),
  });

  const [markup, markupResponse] = usePutMarkupMutation();

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const isEditing = (record: IMarkupListItem) => record.markupId === editingKey;

  const cancel = () => setEditingKey(null);

  const edit = (record: IMarkupListItem) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.markupId);
  };

  const save = async () => {
    try {
      const row: IMarkupListItem = await form.validateFields();
      setEditingKey(null);

      markup({
        body: {
          markupValue: row.markupValue,
          markupPercent: row.markupPercent,
          isPercent: row.isPercent,
          isEnabled: row.isEnabled,
        },
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        markupId: params.id ?? 'frontend error',
      });

      refetch();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  useEffect(() => {
    if (isError || markupResponse.isError) showError((error ?? markupResponse.error) as IResponseError);
  }, [isError, error, markupResponse.isError, markupResponse.error]);

  return {
    searchText,
    searchedColumn,
    searchInput,
    handleSearch,
    data,
    isLoading,
    isError,
    error,
    isFetching,
    handleReset,
    isEditing,
    cancel,
    edit,
    save,
    form,
  };
}
