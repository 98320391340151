import { Col, Form, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { Button } from 'shared/ui/Button/Button';

import { useUserActionsLogic } from '../../model/useUserActionsLogic';

import styles from './userActions.module.scss';

export function UserActions() {
  const { handleBlockUser, handleResetUser2FA } = useUserActionsLogic();

  return (
    <Form layout={'vertical'} className='login-form'>
      <Row gutter={16}>
        <Col span={12}>
          <FormItem label='Блокировка аккаунта (смена статуса для профиля)'>
            <Button className={styles.block} onClick={handleBlockUser}>
              Заблокировать
            </Button>
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <FormItem label='Сброс 2FA (отключение 2FA)'>
            <Button className={styles.reset} onClick={handleResetUser2FA}>
              Сбросить
            </Button>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
}
