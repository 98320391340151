import { store, useAppSelector } from './GlobalRedux/store';
import { Layout, message } from 'antd';
import { ACCESS_TOKEN, setAuthority } from 'entities/Auth';
import { RatesTable } from 'features/RatesTable';
import Cookies from 'js-cookie';
import { AdminsPage } from 'pages/admins';
import { HomePage } from 'pages/home';
import { LoginPage } from 'pages/login';
import { MerchantPage } from 'pages/merchant';
import { NotFoundPage } from 'pages/notFound';
import { WalletPage } from 'pages/wallet';
import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { MessageContext } from 'utils/messageContext';
import { FinOperations } from 'widgets/FinOperations';
import { Footer } from 'widgets/Footer';
import { Header } from 'widgets/Header';
import { MerchantMenu, WalletMenu } from 'widgets/Menu';
import { Rate } from 'widgets/Rate';
import { User } from 'widgets/User';
import { Users } from 'widgets/Users';

import '../shared/styles/globals.scss';

function App() {
  const location = useLocation();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const [messageApi, messageContainer] = message.useMessage();

  useEffect(() => {
    const hasToken = Boolean(Cookies.get(ACCESS_TOKEN));
    store.dispatch(setAuthority({ isLoggedIn: hasToken }));
  }, [store]);

  return (
    <MessageContext.Provider value={[messageApi, messageContainer]}>
      {isLoggedIn ? (
        <Layout>
          <Header />
          <Layout>
            {location.pathname.includes('wallet') && <WalletMenu />}
            {location.pathname.includes('merchant') && <MerchantMenu />}

            <Layout style={{ padding: '0 24px 24px' }}>
              <Routes>
                <Route path='/' element={<HomePage />} />

                <Route path='/wallet' element={<WalletPage />}>
                  <Route path='users' element={<Users />} />
                  <Route path='users/:id' element={<User />} />
                  <Route path='finops' element={<FinOperations />} />
                  <Route path='admins' element={<AdminsPage />} />

                  <Route path='fiat' element={<RatesTable />} />
                  <Route path='fiat/:id' element={<Rate />} />

                  <Route path='crypto' element={<RatesTable />} />
                  <Route path='crypto/:id' element={<RatesTable />} />
                </Route>

                <Route path='/merchant' element={<MerchantPage />}>
                  <Route path='users' element={<Users />} />
                  <Route path='users/:id' element={<User />} />
                  <Route path='finops' element={<FinOperations />} />
                </Route>

                <Route path='*' element={<NotFoundPage />} />
              </Routes>
            </Layout>
          </Layout>
          <Footer />
        </Layout>
      ) : (
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      )}
      {messageContainer}
    </MessageContext.Provider>
  );
}

export default App;
