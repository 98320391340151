import { setAuthority } from './authSlice';
import { useAppDispatch, useAppSelector } from 'app/GlobalRedux/store';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

import { useSignOutMutation } from '../api/authApi';

export const ACCESS_TOKEN = 'accessToken';

export const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const [logout] = useSignOutMutation();
  const { showError } = useAppMessage();

  const setToken = (token: string) => {
    Cookies.set(ACCESS_TOKEN, token);
    dispatch(setAuthority({ isLoggedIn: Boolean(Cookies.get(ACCESS_TOKEN)) }));
  };

  const signOut = async () => {
    try {
      await logout({});
      navigate('/');
      Cookies.remove(ACCESS_TOKEN);
      dispatch(setAuthority({ isLoggedIn: false }));
    } catch (error) {
      showError(error as IResponseError);
    }
  };

  return {
    signOut,
    isLoggedIn,
    setToken,
  };
};
