import { MessageInstance } from 'antd/es/message/interface';
import { useAuth } from 'entities/Auth';
import { ReactElement, createContext, useContext } from 'react';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';

export const MessageContext = createContext<readonly [MessageInstance, ReactElement]>([] as unknown as [MessageInstance, ReactElement]);
const ABORT_ERROR_TYPE = 'AbortError';

export const useAppMessage = () => {
  const [messageApi] = useContext(MessageContext);
  // const { signOut } = useAuth();

  const showError = ({ error, data, status, name }: IResponseError) => {
    if (name === ABORT_ERROR_TYPE) return;
    // if (status === 403 || status === 401) signOut();

    messageApi.open({
      type: 'error',
      content: error || data?.errors?.join('\n') || data?.message,
    });
  };

  const showSuccess = (messageText: string) => {
    messageApi.success(messageText);
  };

  return { showError, showSuccess };
};
