import { Card, TabsProps } from 'antd';
import { CommissionsTable } from 'features/CommissionsTable';
import { MarkupTable } from 'features/MarkupTable';
import { WalletControlTabs } from 'widgets/WalletControlTabs';

const tabs: TabsProps['items'] = [
  {
    key: 'commissions',
    label: 'Commissions',
    children: (
      <Card>
        <CommissionsTable />
      </Card>
    ),
  },
  {
    key: 'markup',
    label: 'Markup',
    children: (
      <Card>
        <MarkupTable />
      </Card>
    ),
  },
];

export function Rate() {
  return <WalletControlTabs tabs={tabs} />;
}
