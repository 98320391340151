/**
 * This function finds all unique values by a filter word and return transformed data for a table filter
 * @param data an array
 * @param filter a word which we use to find filter values
 * @returns
 */

export function transformDataForTableFilter<T>(data: T[], filter: keyof T) {
  const uniqueDataMap: Record<keyof T, boolean> = Object.create(null);
  const uniqueData: { text: keyof T; value: keyof T }[] = [];

  data.forEach((item) => {
    const key = item[filter] as keyof T;
    if (key in uniqueDataMap === false) {
      // Если значение не существует в uniqueDataMap, добавляем его в массив и отмечаем в uniqueDataMap
      uniqueData.push({ text: key, value: key });
      uniqueDataMap[key] = true;
    }
  });

  return uniqueData;
}
