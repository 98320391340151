import { Menu as AntMenu, MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { RiseOutlined, UserOutlined, TransactionOutlined, SmileOutlined } from '@ant-design/icons';

import styles from './merchantMenu.module.scss';

const items: MenuProps['items'] = [
  {
    label: <NavLink to='/merchant/users'>Пользователи</NavLink>,
    key: 'users',
    icon: React.createElement(UserOutlined),
  },
  {
    label: <NavLink to='/merchant/finops'>Фин. операции</NavLink>,
    key: 'finops',
    icon: React.createElement(TransactionOutlined),
  },
  {
    label: 'Курсы валют',
    key: 'rates',
    icon: React.createElement(RiseOutlined),
    children: [
      {
        key: 'fiat',
        label: <NavLink to='/merchant/fiat'>Fiat</NavLink>,
        children: [
          { key: 'fiat_deposit', label: <NavLink to='/merchant/fiat/deposit'>Deposit</NavLink> },
          { key: 'fiat_withdrawal', label: <NavLink to='/merchant/fiat/withdrawal'>Withdrawal</NavLink> },
          { key: 'fiat_transfer', label: <NavLink to='/merchant/fiat/transfer'>Transfer</NavLink> },
          { key: 'fiat_exchange', label: <NavLink to='/merchant/fiat/exchange'>Exchange</NavLink> },
        ],
      },
      {
        key: 'crypto',
        label: <NavLink to='/wallet/crypto'>Crypto</NavLink>,
        children: [
          { key: 'crypto_deposit', label: <NavLink to='/wallet/crypto/deposit'>Deposit</NavLink> },
          { key: 'crypto_withdrawal', label: <NavLink to='/wallet/crypto/withdrawal'>Withdrawal</NavLink> },
          { key: 'crypto_transfer', label: <NavLink to='/wallet/crypto/transfer'>Transfer</NavLink> },
          { key: 'crypto_exchange', label: <NavLink to='/wallet/crypto/exchange'>Exchange</NavLink> },
        ],
      },
    ],
  },
  {
    label: <NavLink to='/wallet/admins'>Администраторы</NavLink>,
    key: 'admins',
    icon: React.createElement(SmileOutlined),
  },
];

export function MerchantMenu() {
  const location = useLocation();

  return (
    <Sider className={styles.wrapper}>
      <AntMenu mode='inline' className={styles.menu} items={items} selectedKeys={[location.pathname.split('/').at(-1) ?? 'users']} />
    </Sider>
  );
}
