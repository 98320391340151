import { TPanelType } from 'shared/types/Types/TPanelType';
import { IUsersRequest } from 'widgets/Users';

import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '../../../shared/api';
import { IChangeTransactionRequest, ITransaction, ITransactionsResponse } from '../model/types';

export const transactionsApi = createApi({
  reducerPath: 'transactionsApi',
  baseQuery,
  tagTypes: ['TransactionsData', 'TransactionData'],
  endpoints: (builder) => ({
    getTransactions: builder.query<ITransactionsResponse, IUsersRequest>({
      providesTags: ['TransactionsData'],
      query: (params) => {
        const updatedParams = {
          'sorting[createdAt]': params.createdAt,
          'paging[page]': params.page,
          'paging[perPage]': params.perPage,
          'filter[email]': params.email,
          'filter[userId]': params.userId,
          'filter[createdFrom]': params.createdFrom,
          'filter[createdTo]': params.createdTo,
          'filter[hasBalance]': params.hasBalance,
          'filter[profileId]': params.profileId,
        };

        return {
          url: `/${params.panelType}/transactions/`,
          params: updatedParams,
        };
      },
    }),
    getTransactionStatuses: builder.query<{ list: string[] }, { panelType: TPanelType }>({
      query: (params) => {
        return {
          url: `/${params.panelType}/transaction-statuses`,
        };
      },
    }),
    getTransactionData: builder.query<{ data: ITransaction }, { panelType: TPanelType; transactionId: ITransaction['transactionId'] }>({
      providesTags: ['TransactionData'],
      query: (params) => {
        return {
          url: `/${params.panelType}/transactions/${params.transactionId}`,
        };
      },
    }),
    putTransactionStatus: builder.mutation<{ list: string[] }, IChangeTransactionRequest>({
      query: ({ panelType, transactionId, body }) => {
        return {
          url: `/${panelType}/transactions/${transactionId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['TransactionsData'],
    }),
  }),
});

export const { useGetTransactionStatusesQuery, useGetTransactionsQuery, useGetTransactionDataQuery, usePutTransactionStatusMutation } =
  transactionsApi;
