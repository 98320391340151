import { IUser } from './types';
import { InputRef } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { useAppDispatch } from 'app/GlobalRedux/store';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

import { useGetUsersQuery, usersApi } from '../api/usersApi';

export type DataIndex = keyof IUser;

export function useUsersLogic() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [hasBalance, setHasBalance] = useState(false);
  const searchInput = useRef<InputRef>(null);
  const [filteredData, setFilteredData] = useState<IUser[] | undefined>();
  const { data, isLoading, isError, error } = useGetUsersQuery({
    panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    page: 1,
    perPage: 50,
  });
  const { showError } = useAppMessage();

  const showUsersWithBalance = (e: CheckboxChangeEvent) => {
    setHasBalance(e.target.checked);
  };

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);

    // let request: QueryAction<WalletCryptoRateQueryParams, ResponseData<CryptoRateAndCommission>, 'cryptoRampApi'>;
    // dispatch(
    //   usersApi.endpoints.getUsers.initiate({
    //     panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    //     createdAt: undefined,
    //     createdFrom: undefined,
    //     createdTo: undefined,
    //     email: dataIndex === 'email' ? selectedKeys[0] : undefined,
    //     hasBalance: hasBalance,
    //     page: 1,
    //     perPage: 50,
    //     profileId: undefined,
    //     userId: dataIndex === 'userId' ? selectedKeys[0] : undefined,
    //   })
    // );
  };

  const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  useEffect(() => {
    if (isError) {
      showError(error as IResponseError);
    }
  }, [isError, error, showError]);

  useEffect(() => {
    if (isLoading === false && data) {
      setFilteredData(data.list);
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(
      usersApi.endpoints.getUsers.initiate({
        panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
        createdAt: undefined,
        createdFrom: undefined,
        createdTo: undefined,
        email: searchedColumn === 'email' ? searchText : undefined,
        hasBalance: hasBalance,
        page: 1,
        perPage: 50,
        profileId: undefined,
        userId: searchedColumn === 'userId' ? searchText : undefined,
      })
    );
  }, [hasBalance, searchedColumn, searchText]);

  return {
    searchInput,
    handleSearch,
    handleReset,
    showUsersWithBalance,
    filteredData,
    searchedColumn,
    searchText,
    isLoading,
  };
}
