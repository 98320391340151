import Table, { ColumnsType } from 'antd/es/table';
import { useGetTransactionsQuery } from 'entities/Transactions';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

import styles from './payHistory.module.scss';

interface DataType {
  operationId: string;
  type: string;
  source: string;
  before: string;
  after: string;
  currency: string;
  amount: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'UID',
    dataIndex: 'operationId',
    key: 'UID',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Account number',
    dataIndex: 'source',
    key: 'account number',
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    // render: (value: string, { currencyCode }) => `${value ?? ''} ${currencyCode}`,
  },
];

export function PayHistory() {
  const location = useLocation();
  const params = useParams();
  const { showError } = useAppMessage();

  const { data, isLoading, isError, error } = useGetTransactionsQuery({
    panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    userId: params.id ?? 'frontend error',
  });

  useEffect(() => {
    if (isError) showError(error as IResponseError);
  }, [isError, error, showError]);

  return (
    <div className={styles.container}>
      <Table
        rowKey={(row) => row.operationId}
        className={styles.table}
        pagination={false}
        columns={columns}
        dataSource={data?.list.map((transaction) => {
          return {
            operationId: transaction.transactionId,
            type: transaction.transactionType,
            source: transaction.walletId,
            before: transaction.amount,
            after: transaction.amount,
            currency: transaction.currencyCode,
            amount: transaction.amount,
          };
        })}
        loading={isLoading}
      />
    </div>
  );
}
