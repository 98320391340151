import { ACCESS_TOKEN } from 'entities/Auth';
import Cookies from 'js-cookie';

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseQuery = fetchBaseQuery({
  baseUrl: 'https://admin-swagger.dev.c2c.payteza.com/mock/api/latest',
  prepareHeaders: (headers) => {
    // const localeCode = navigator.language.toUpperCase();
    // headers.set('localeCode', localeCode);

    const token = Cookies.get(ACCESS_TOKEN);

    if (token) {
      headers.set('Authorization', token);
    }

    return headers;
  },
});
