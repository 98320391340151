import { Card, TabsProps } from 'antd';
import { PayHistory } from 'features/PayHistory';
import { UserAccounts } from 'features/UserAccounts';
import { UserActions } from 'features/UserActions';
import { UserPersonalData } from 'features/UserPersonalData';
import { WalletControlTabs } from 'widgets/WalletControlTabs';

const tabs: TabsProps['items'] = [
  {
    key: 'user',
    label: 'Данные пользователя',
    children: (
      <Card>
        <UserPersonalData />
      </Card>
    ),
  },
  {
    key: 'accounts',
    label: 'Счета',
    children: (
      <Card>
        <UserAccounts />
      </Card>
    ),
  },
  {
    key: 'operations',
    label: 'Список операций',
    children: (
      <Card>
        <PayHistory />
      </Card>
    ),
  },
  {
    key: 'actions',
    label: 'Действия',
    children: (
      <Card>
        <UserActions />
      </Card>
    ),
  },
];

export function User() {
  return <WalletControlTabs tabs={tabs} />;
}
