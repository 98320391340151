import { Input, Space, Table } from 'antd';
import { ColumnType, ColumnsType } from 'antd/es/table/interface';
import { ITransaction } from 'entities/Transactions';
import Highlighter from 'react-highlight-words';
import { Button } from 'shared/ui/Button/Button';
import CustomModal from 'shared/ui/CustomModal/CustomModal';
import CustomSelect from 'shared/ui/CustomSelect/CustomSelect';
import ModalTitle from 'shared/ui/ModalTitle/ModalTitle';
import { transformDataForTableFilter } from 'utils/transformDataForTableFilter';

import { SearchOutlined } from '@ant-design/icons';

import fingerInCircle from '../../../../assets/icons/fingerInCircle.svg';
import { DataIndex, useFinOperationsTableLogic } from '../../model/useFinOperationsTableLogic';

import styles from './finOperationsTable.module.scss';

export function FinOperationsTable() {
  const logic = useFinOperationsTableLogic();

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<ITransaction> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className={styles.filter_dropdown} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={logic.searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => logic.handleSearch(selectedKeys as string[], confirm, dataIndex)}
        />
        <Space>
          <Button onClick={() => logic.handleSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size='small'>
            Search
          </Button>
          <Button onClick={() => clearFilters && logic.handleReset(clearFilters)} size='small'>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      (record[dataIndex] as string)
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => logic.searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      logic.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[logic.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<ITransaction> = [
    {
      title: 'ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      ...getColumnSearchProps('transactionId'),
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt).getDate() - new Date(b.createdAt).getDate(),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      filters: transformDataForTableFilter<ITransaction>(logic.data?.list ?? [], 'transactionType'),
    },
    {
      title: 'User ID',
      dataIndex: 'userId',
      key: 'userId',
      ...getColumnSearchProps('userId'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => `${amount}`,
    },
    {
      title: 'Currency',
      dataIndex: 'currencyCode',
      key: 'currencyCode',
      filters: transformDataForTableFilter(logic.data?.list ?? [], 'currencyCode'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: transformDataForTableFilter(logic.data?.list ?? [], 'status'),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'btn',
      render: (_, record) => <Button onClick={() => logic.setEditTransactionId(record.transactionId)}>Change status</Button>,
    },
  ];

  return (
    <div className=''>
      <Table
        rowKey={(row) => row.transactionId}
        columns={columns}
        dataSource={logic.data?.list}
        loading={logic.isLoading || logic.isFetching}
      />

      <CustomModal
        width={560}
        className={styles.modal}
        isOpen={Boolean(logic.editTransactionId)}
        onClose={() => logic.setEditTransactionId(null)}
        centered
      >
        <ModalTitle>
          <img src={fingerInCircle} alt='finger in circle' />
          <span>
            Change transaction status <br /> id: {logic.editTransactionId}
          </span>
        </ModalTitle>
        <br />
        <form onSubmit={logic.changeTransactionStatus}>
          <CustomSelect
            id={'transaction-statuses'}
            className={styles.net_select}
            value={logic.transactionStatus}
            onChange={(value) => logic.setTransactionStatus(value)}
            options={logic.transactionStatuses?.list.map((item) => ({ value: item, label: item }))}
            error={false}
          />
          <Button className={styles.confirm_btn}>Confirm</Button>
        </form>
      </CustomModal>
    </div>
  );
}
