import { Form, Input, Space, Tag } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { ColumnType } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { Button } from 'shared/ui/Button/Button';
import { EditableCell } from 'shared/ui/EditableCell/EditableCell';

import { CloseCircleTwoTone, SaveTwoTone, SearchOutlined } from '@ant-design/icons';

import { IMarkupListItem } from '../../model/types';
import { CustomColumnType, DataIndex, useMarkupTableLogic } from '../../model/useMarkupTableLogic';

import styles from './markupTable.module.scss';

export function MarkupTable() {
  const logic = useMarkupTableLogic();

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<IMarkupListItem> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={logic.searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => logic.handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button onClick={() => logic.handleSearch(selectedKeys as string[], confirm, dataIndex)} icon={<SearchOutlined />} size='small'>
            Search
          </Button>
          <Button onClick={() => clearFilters && logic.handleReset(clearFilters)} size='small'>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      (record[dataIndex] as string)
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => logic.searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      logic.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[logic.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: CustomColumnType<IMarkupListItem>[] = [
    {
      title: 'Currency',
      dataIndex: 'couple',
      key: 'couple',
      render: (_, record) => (
        <div>
          {record.currencyCodeFrom}-{record.currencyCodeTo}
        </div>
      ),
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      key: 'operation',
      // width: '110px',
      // filters: transformDataForTableFilter<ICommissionsListItem>(logic.data?.list ?? [], 'operation'),
      // onFilter: logic.handelCommissionFilter,
    },
    {
      title: 'Markup',
      dataIndex: 'markupValue',
      key: 'markupValue',
      width: '20%',
      editable: true,
      // filters: transformDataForTableFilter(data, 'fixMarkup'),
    },
    {
      title: 'Markup (%)',
      dataIndex: 'markupPercent',
      key: 'markupPercent',
      width: '20%',
      editable: true,
      render: (value) => `${value}%`,
    },
    {
      title: 'Is Percent',
      dataIndex: 'isPercent',
      key: 'isPercent',
      editable: true,
      width: '110px',
      render: (isUsed) =>
        isUsed ? (
          <Tag color={'green'} key={'plus'}>
            +
          </Tag>
        ) : (
          <Tag color={'volcano'} key={'plus'}>
            -
          </Tag>
        ),
    },
    {
      title: 'Is Enabled',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      width: '110px',
      editable: true,
      render: (isUsed) =>
        isUsed ? (
          <Tag color={'green'} key={'plus'}>
            +
          </Tag>
        ) : (
          <Tag color={'volcano'} key={'plus'}>
            -
          </Tag>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: '80px',
      render: (_, record) => {
        const editable = logic.isEditing(record);
        return editable ? (
          <div className={styles.control_btns_container}>
            <SaveTwoTone className={styles.control_btn} onClick={logic.save} />
            <CloseCircleTwoTone className={styles.control_btn} onClick={logic.cancel} twoToneColor={'red'} />
          </div>
        ) : (
          <Button onClick={() => logic.edit(record)}>EDIT</Button>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record: IMarkupListItem) => ({
        record,
        inputType: col.dataIndex === 'isPercent' || col.dataIndex === 'isEnabled' ? 'checkbox' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: logic.isEditing(record),
      }),
    };
  });

  return (
    <Form form={logic.form} component={false}>
      <Table
        rowKey={(row) => row.markupId}
        columns={mergedColumns as ColumnsType<IMarkupListItem>}
        dataSource={logic.data?.list}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        pagination={{
          defaultPageSize: 50,
          showSizeChanger: false,
          // pageSizeOptions: ['50', '100'],
        }}
        loading={logic.isLoading || logic.isFetching}
      />
    </Form>
  );
}
