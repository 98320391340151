import { Menu as AntMenu, MenuProps } from 'antd';
import Sider from 'antd/es/layout/Sider';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { RiseOutlined, UserOutlined, TransactionOutlined, SmileOutlined, DollarOutlined } from '@ant-design/icons';

import styles from './walletMenu.module.scss';

export function WalletMenu() {
  const location = useLocation();

  const items: MenuProps['items'] = [
    {
      label: `${location.pathname.includes('wallet') ? 'Delta Wallet' : 'Delta Merchant'}`,
      key: 'users',
      type: 'group',
      children: [
        {
          label: <NavLink to='/wallet/users'>Пользователи</NavLink>,
          key: 'users',
          icon: React.createElement(UserOutlined),
        },
        {
          label: <NavLink to='/wallet/finops'>Фин. операции</NavLink>,
          key: 'finops',
          icon: React.createElement(TransactionOutlined),
        },
        {
          label: <NavLink to={`/${location.pathname.includes('wallet') ? 'wallet' : 'merchant'}/admins`}>Администраторы</NavLink>,
          key: 'admins',
          icon: React.createElement(SmileOutlined),
        },
      ],
    },
    {
      label: 'Курсы валют',
      key: 'rates',
      type: 'group',
      children: [
        {
          key: 'fiat',
          label: <NavLink to='/wallet/fiat'>Fiat</NavLink>,
          icon: React.createElement(RiseOutlined),
        },
        {
          key: 'crypto',
          label: <NavLink to='/wallet/crypto'>Crypto</NavLink>,
          icon: React.createElement(DollarOutlined),
        },
      ],
    },
  ];
  return (
    <Sider className={styles.wrapper}>
      <AntMenu mode='inline' className={styles.menu} items={items} selectedKeys={[location.pathname.split('/').at(-1) ?? 'users']} />
    </Sider>
  );
}
