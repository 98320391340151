import { InputRef } from 'antd';
import { FilterConfirmProps } from 'antd/es/table/interface';
import {
  ITransaction,
  useGetTransactionDataQuery,
  useGetTransactionStatusesQuery,
  useGetTransactionsQuery,
  usePutTransactionStatusMutation,
} from 'entities/Transactions';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IResponseError } from 'shared/types/Interfaces/IResponseMessage';
import { useAppMessage } from 'utils/messageContext';

export type DataIndex = keyof ITransaction;

export function useFinOperationsTableLogic() {
  const location = useLocation();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [editTransactionId, setEditTransactionId] = useState<ITransaction['transactionId'] | null>(null);
  const [transactionStatus, setTransactionStatus] = useState<string | null>(null);
  const { showError } = useAppMessage();

  const { data, isLoading, isFetching, isError, error } = useGetTransactionsQuery(
    {
      panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
    },
    { pollingInterval: 30000 }
  );

  const { data: transactionStatuses } = useGetTransactionStatusesQuery({
    panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
  });

  const { data: transactionData } = useGetTransactionDataQuery(
    {
      panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      transactionId: editTransactionId ?? 'frontend error',
    },
    { skip: Boolean(editTransactionId === null) }
  );

  const [transaction] = usePutTransactionStatusMutation();

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  function changeTransactionStatus(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    transaction({
      body: { status: transactionStatus ?? 'frontend error' },
      panelType: location.pathname.includes('wallet') ? 'wallet' : 'merchant',
      transactionId: editTransactionId ?? 'frontend error',
    });
    setEditTransactionId(null);
  }

  useEffect(() => {
    if (transactionData?.data.status) setTransactionStatus(transactionData?.data.status);
  }, [transactionData]);

  useEffect(() => {
    if (isError) showError(error as IResponseError);
  }, [isError, error, showError]);

  return {
    transactionStatuses,
    setTransactionStatus,
    transactionStatus,
    changeTransactionStatus,
    searchInput,
    handleSearch,
    handleReset,
    searchedColumn,
    searchText,
    data,
    setEditTransactionId,
    editTransactionId,
    isLoading,
    isFetching,
  };
}
