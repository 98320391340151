import { Col, Form, Row, Skeleton } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { Button } from 'shared/ui/Button/Button';
import Input from 'shared/ui/Input/Input';
import InputPassword from 'shared/ui/InputPassword/InputPassword';
import { transformDateToUserFriendly } from 'utils/transformDateToUserFriendly';

import useUserPersonalDataLogic from '../../model/useUserPersonalDataLogic';

import styles from './userPersonalData.module.scss';

export function UserPersonalData() {
  const { isLoading, data, userData, changeUserData, balanceData, putUserData, isBalanceLoading } = useUserPersonalDataLogic();

  return isLoading ? (
    <Skeleton active />
  ) : (
    <Form onFinish={putUserData} layout={'vertical'} className='login-form'>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem label='ID'>
            <Input placeholder='ID' value={data?.data?.userId ?? ''} readOnly disabled />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label='Имя'>
            <Input name='name' placeholder='Имя' value={userData?.name ?? ''} onChange={changeUserData} />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label='Фамилия'>
            <Input name='surname' placeholder='Фамилия' value={userData?.surname ?? ''} onChange={changeUserData} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <FormItem label='Email'>
            <Input name='email' placeholder='Email' value={userData?.email ?? ''} onChange={changeUserData} />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label='Номер телефона'>
            <Input name='phone' placeholder='Номер телефона' value={userData?.phone ?? ''} onChange={changeUserData} />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label='Страна'>
            <Input name='countryName' placeholder='Страна' value={userData?.countryName ?? ''} onChange={changeUserData} />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <FormItem label='Город'>
            <Input name='town' placeholder='Город' value={userData?.town ?? ''} onChange={changeUserData} />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label='Адрес'>
            <Input name='address' placeholder='Адрес' value={userData?.address ?? ''} onChange={changeUserData} />
          </FormItem>
        </Col>

        <Col span={8}>
          <FormItem label='Дата регистрации'>
            <Input
              placeholder='Дата регистрации'
              value={userData?.createdAt ? transformDateToUserFriendly(userData?.createdAt) : ''}
              readOnly
              disabled
            />
          </FormItem>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <FormItem label='Общий баланс в USD'>
            {isBalanceLoading ? (
              <Skeleton.Input active />
            ) : (
              <Input placeholder='Общий баланс в USD' value={balanceData?.data.balance ?? ''} readOnly disabled />
            )}
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label='Изменение пароля'>
            <InputPassword name='password' placeholder='Введите пароль' value={userData?.password ?? ''} onChange={changeUserData} />
          </FormItem>
        </Col>
      </Row>
      <Col span={24} className={styles.btn_column}>
        <Button className={styles.change_btn}>Change</Button>
      </Col>
    </Form>
  );
}
