import { Button as AntButton } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { TClassName } from 'shared/types/Types/TClassName';
import { cn } from 'utils/classnames';

import styles from './button.module.scss';

export function Button({
  children,
  className,
  href,
  onClick,
  ...props
}: {
  children: React.ReactNode | string;
  className?: TClassName;
  href?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  size?: SizeType;
}) {
  return (
    <AntButton htmlType='submit' onClick={onClick} className={cn(styles.btn, className)} href={href} {...props}>
      {children}
    </AntButton>
  );
}
